import React, { useEffect, useState } from "react";

import {Alert, Button, Card, CardHeader, CardContent, Grid} from "@mui/material";
import { useMediaQuery, useTheme } from "@mui/system";

import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {navigate, useIntl} from "gatsby-plugin-intl";

import Seo from "components/common/Seo";
import LayoutBo from "components/backoffice/common/layout/LayoutBo";
import themeMuiV5 from "components/common/ThemeMuiV5";
import {GROUP} from "utils/constants/back-office-constants";

import {RhfListOfValuesSelect, RhfSwitch, RhfTextField, RhfTextFieldMultiline} from 'components/backoffice/common/RhfFields';
import ResultSnackbars from "components/backoffice/common/ResultSnackbars";
import ConfirmationDialog from "components/common/ConfirmationDialog";
import AvatarUpload from "ui/AvatarUpload";
import RhfListOfValues from "ui/RhfListOfValues";

import AddEditInfosBloc from "components/backoffice/academics/AddEditInfosBloc";
import AddEditMultiValuesBloc from "components/backoffice/academics/AddEditMultiValuesBloc";


import { validateAcademicToAdd, validateAcademicToEdit } from "utils/academics-schema";
import academicService from "services/api/academicService";

const defaultStyles = {
    textField: {
        width: "90%",
        justifyContent: "center",
        alignContent: "center"
    }
};


const AddEditAcademicPage = ({ academicId}) => {
    const intl = useIntl();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const isEditPage = academicId ? true : false;
    const [linksByPropertyMap, setLinksByPropertyMap] = useState(new Map());

    const mapAcademicBack2Front = (academic) => {
        let academicMapped = {
            academicId: academic.academicId,
            userId: academic.userId,
            mediaId: academic.photo?.mediaId,
            picture: academic.photo,
            srcPicture: academic.photo?`data:image/jpeg;base64,`+ academic.photo.stream : null,
            visible: academic.visible,
            lastConnectionDate: academic.lastConnectionDate, 
            firstName: academic.firstName,
            lastName: academic.lastName,
            email: academic.email,
            description: academic.description,
            universityId: academic.universityId,
            universityUnitId: academic.universityUnitId,
            academicTitles: academic.titles,
            academicDisciplines: academic.academicDisciplines,
            expertiseFields: academic.fieldsOfExpertise,
            workingLanguages: academic.workingLanguages,
            researchCountries: academic.countriesOfResearch,
            researchProjects: academic.researchProjects
        };
        if(academic.informationLinks) {
            let linksObject = convertLinkList2LinksObject(academic.informationLinks);
            academicMapped.links = linksObject;
        }    
        return academicMapped;
    }

    const convertLinkList2LinksObject = (dbLinkList) => {
        const linkCodeByProperty = {
            linkPublicationWebPage: 'PUBWB',
            linkPublicationFeedLink: 'PUBFL',
            linkOrcid: 'ORC',
            linkProWebPage: 'PROWB',
            linkPersoWebPage: 'PERWB',
            linkGoogleScholar: 'GS',
            linkResearchGateProfile: 'RG',
            linkLinkedIn: 'LI',
            linkTwitter: 'TW'
        };
        const dbLinksByCodeMap = new Map(dbLinkList.map(infoLink => [infoLink.type, infoLink]));
        let dbLinksByPropertyMap = new Map();
        let linksObject = {};
        for (const [property, code] of Object.entries(linkCodeByProperty)) {
            const dbLink = dbLinksByCodeMap.get(code);
            if (dbLink) {
                linksObject[property] = dbLink?.url;
                dbLinksByPropertyMap.set(property, dbLink);
            }
        }
        setLinksByPropertyMap(dbLinksByPropertyMap);
        return linksObject;
    }

    const mapAcademicFront2Back = (academic, isEditPage) => {
        let informationLinks = [];
        for (const [key, value] of Object.entries(academic.links)) {
            let infoLink = {"linkId": null, "code": key, "url": value};
            if (isEditPage) {
                const dbLink = linksByPropertyMap.get(key);
                infoLink.linkId = dbLink?.linkId;
            }
            informationLinks.push(infoLink);
        }
        academic.informationLinks = informationLinks;
        return academic;
    }

    let academicDefaultValues = {
        picture: null,
        srcPicture: null,
        visible: false,
        firstName: '',
        lastName: '',
        email: '',
        description: '',
        universityId: '',
        universityUnitId: '',
        academicTitles: [],
        academicDisciplines: [],
        expertiseFields: [],
        workingLanguages: [],
        researchCountries: [],
        researchProjects: [],
        links: {
            linkPublicationWebPage: '',
            linkPublicationFeedLink: '',
            linkOrcid: '',
            linkProWebPage: '',
            linkPersoWebPage: '',
            linkGoogleScholar: '',
            linkResearchGateProfile: '',
            linkLinkedIn: '',
            linkTwitter: ''
        }
    };

    const useFormMethods = useForm({
            resolver: yupResolver(isEditPage ? validateAcademicToEdit : validateAcademicToAdd),
            defaultValues: academicDefaultValues,
            mode: "onChange",
    });

    const [resultMessage, setResultMessage] = useState("");
    const [openSuccess, setOpenSuccess] = useState(false);
    const [openError, setOpenError] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [unitList, setUnitList] = useState([]);
    const [lastConnectionDate, setLastConnectionDate] = useState(null);
    
    useEffect(() => {
        const fetchAcademicData = async () => {
            try {
                const results = await academicService.searchAcademicById(academicId);
                const dbAcademic = results.data;
                let editAcademic = mapAcademicBack2Front(dbAcademic);
                await updateUniversityUnitList(editAcademic.universityId);
                useFormMethods.reset(editAcademic);
                setLastConnectionDate(editAcademic.lastConnectionDate);
            } catch (error) {
                console.log("error : ", error);
                navigate("/back-office/faculty");
            }
        };
        if (!!academicId) {
            fetchAcademicData();
        }
    }, [academicId]);



    const onSubmit = academic => {
        setDisableSubmit(true);
        setOpenSuccess(false);
        setOpenError(false);
        academic = mapAcademicFront2Back(academic, isEditPage);
        academicService
            .saveAcademicWithPicture(academic)
            .then((result) => {
                setResultMessage("academic.create.success");
                setOpenSuccess(true);
                setDisableSubmit(false);
                goBack();
            })
            .catch((error) => {
                handleError(error);
            });

    };

    const handleError = (error) => {
        let errLabel = "academic.create.error";
        if (error && error.data && error.data.errorCode) {
            console.log("error : ", error);
            errLabel = error.data.errorCode;
        }
        console.log("errLabel : ", errLabel);
        setResultMessage(errLabel);
        setOpenError(true);
        setDisableSubmit(false);
    }

    const goBack = () => {
        try {
            window.history.back();
        } catch (error) {
            console.log("error : ", error);
        }
    };


    const onError = (errors, e) => console.log(errors, e);

    const onChangeUniversity = async (university) => {
        await updateUniversityUnitList(university.value);
    }

    const updateUniversityUnitList = async (universityId) => {
        await academicService.findUnitsByUniversityId(universityId)
            .then(response => {
                const univUnitList = response.data;
                if (univUnitList) {
                    setUnitList(univUnitList);
                } else {
                    console.log("no data for university id : ", universityId);
                }
            }).catch(error => {
                console.log("error : ", error);
            });
    }  
    
    const handleValidateAvatarChange = (event) => {
        setOpenSuccess(false);
        setOpenError(false);
        const selectedFile = event?.target?.files[0];
    
        if (!['image/jpg', 'image/jpeg', 'image/webp', 'image/png'].includes(selectedFile.type)) {
            setResultMessage("error.image.type");
            setOpenError(true);
            return false;
        }
        const MAX_SIZE = 1000 * 1024;
        if (selectedFile.size > MAX_SIZE) {
            setResultMessage("error.image.size");
            setOpenError(true);
            return false;
        }
        return true;
    }

    return (
        <LayoutBo>
            <Seo title={intl.formatMessage({id: "academic.edit"})} lang={intl.locale}/>
            <Card sx={{
                backgroundColor: themeMuiV5.palette.background.paper,
                maxHeight: "100%",
                paddingTop: themeMuiV5.spacing(1),
                color: themeMuiV5.palette.primary.main
            }}>

                <CardHeader sx={{
                    backgroundColor: themeMuiV5.palette.primary.main,
                    textAlign: "center",
                    paddingTop: themeMuiV5.spacing(1),
                    color: themeMuiV5.palette.primary.contrastText
                }}
                            title={
                                isEditPage
                                    ? intl.formatMessage({id: "academic.edit"})
                                    : intl.formatMessage({id: "academic.new"})
                            }/>

                <CardContent sx={{backgroundColor: themeMuiV5.palette.background.paper, width: '100%'}}>

                    <ResultSnackbars
                        openError={openError}
                        openSuccess={openSuccess}
                        messageId={resultMessage}
                        setOpenSuccess={setOpenSuccess}
                        setOpenError={setOpenError}
                    />        

                    <form onSubmit={useFormMethods.handleSubmit(onSubmit, onError)}>

                    { lastConnectionDate != null ?
                        <Alert severity="warning" sx={{ width: '100%', margin: '10px', padding: '1px' }}>
                            {intl.formatMessage({id: "academic.edit.infoMessage.namesNotEditable"})}
                        </Alert>
                        :
                        <></>
                    }   

                    <Grid container rowSpacing={4} justifyContent={"center"} 
                        sx={{ 
                            width: '100%', 
                            minWidth: 0, 
                            marginLeft:'10px' 
                        }}>
                        
                        <Grid item xs={12} md={2} 
                            style={{ 
                                display:'flex', 
                                justifyContent: 'center'
                            }}> 
                            <AvatarUpload
                                label={"academic.avatar.image"}
                                pictureFileName={"picture"}
                                pictureSourceName={"srcPicture"}
                                useFormMethods={useFormMethods}
                                handleValidateAvatarChange={handleValidateAvatarChange}                                
                            />
                        </Grid>
                        <Grid item xs={12} md={10} > 
                            <RhfTextFieldMultiline
                                    sx={{ width: isMobile ? '90%' : '95%' }}
                                    style={{ marginLeft: '20px' }}
                                    label="academic.description"
                                    name="description"
                                    //required={true}
                                    control={useFormMethods.control}
                                    rows={6}
                                    multiline={true}
                                    error={useFormMethods.formState.errors ? useFormMethods.formState.errors.description : null}
                                    maxLength={3000}                                      
                            />
                        </Grid>
                        
                        <Grid item xs={12} md={4} style={{ display:'flex', justifyContent:'center'}}> 
                            <RhfSwitch label="academic.visible" name="visible" control={useFormMethods.control}/>
                        </Grid>
                        <Grid item xs={12} md={4}> 
                            <RhfListOfValuesSelect
                                name="universityId"
                                label={"academic.university"}
                                //required={true}
                                group={GROUP.university}
                                sx={defaultStyles.textField}
                                control={useFormMethods.control}
                                onChangeField={onChangeUniversity}
                                error={useFormMethods.formState.errors ? useFormMethods.formState.errors.universityId : null}
                            />
                        </Grid>   
                        <Grid item xs={12} md={4}>
                            <RhfListOfValues
                                name="universityUnitId"
                                label={"academic.universityUnit"}
                                //required={true}
                                items={unitList}
                                group={GROUP.universityUnit}
                                sx={defaultStyles.textField}                                
                                control={useFormMethods.control}
                                error={useFormMethods.formState.errors ? useFormMethods.formState.errors.universityUnitId : null}
                            />
                        </Grid>   

                        <Grid item xs={12} md={4}>
                            <RhfTextField
                                label="academic.lastName"
                                name="lastName"
                                textTransform="uppercase"
                                //required={true}
                                disabled={lastConnectionDate!=null}
                                sx={defaultStyles.textField}
                                control={useFormMethods.control}
                                error={useFormMethods.formState.errors ? useFormMethods.formState.errors.lastName : null}
                            />
                        </Grid>        
                        <Grid item xs={12} md={4}>
                            <RhfTextField
                                label="academic.firstName"
                                name="firstName"
                                textTransform="capitalize"
                                //required={true}
                                disabled={lastConnectionDate!=null}
                                sx={defaultStyles.textField}
                                control={useFormMethods.control}
                                error={useFormMethods.formState.errors ? useFormMethods.formState.errors.firstName : null}
                            />
                        </Grid>        
                        <Grid item xs={12} md={4}>
                            <RhfTextField
                                label="academic.email"
                                name="email"
                                disabled={isEditPage}
                                //required={true}
                                sx={defaultStyles.textField}
                                control={useFormMethods.control}
                                error={useFormMethods.formState.errors ? useFormMethods.formState.errors.email : null}/>
                        </Grid>        

                        <AddEditMultiValuesBloc id="form-multivalues-bloc" useFormHook={useFormMethods}/>

                        <AddEditInfosBloc id="form-info-bloc" useFormHook={useFormMethods} />

                        <Grid item margin={5} justifyContent={"center"} alignContent={"center"}>
                            <Button
                                color="primary"
                                type="submit"
                                variant="contained"
                                disabled={disableSubmit}
                            >
                                {intl.formatMessage({id: "academic.save"})}
                            </Button>
                        
                            <ConfirmationDialog
                                isModified={useFormMethods.formState.isDirty}
                                listingUrl={`/back-office/faculty`}
                            />
                        </Grid>
                         
                    </Grid>
                    </form>   
                </CardContent>
            </Card>
        </LayoutBo>
    );
};

export default AddEditAcademicPage;



