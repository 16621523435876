import React, { useState } from "react";

import {Controller } from "react-hook-form";
import {useIntl} from "gatsby-plugin-intl"; 
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';


const RhfDatePicker = ({ 
    name,
    label,
    control,
    disablePast = true, 
    defaultValue = null,
    error = null,    
    methods = null,
    ...props
}) => {
    const intl = useIntl();

    const onClear = (e) => {
        e.stopPropagation();
        if (methods) {
            const newDefaultValues = Object.assign({}, methods.getValues(), { deadline: null });
            methods.reset(newDefaultValues);
        }
    }

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            render={({ field }) => (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        label={intl.formatMessage({ id: label })}
                        value={field.value}
                        inputRef={field.ref}
                        disablePast={disablePast}
                        onChange={
                            (date) => field.onChange(date)
                        }
                        slotProps={{
                            textField: {
                                helperText: error ? intl.formatMessage({ id: error.message }) : null,
                            },
                        }}
                        {...props}
                    />
                </LocalizationProvider>
            )}
        />
    );
};

export default RhfDatePicker;